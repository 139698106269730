import { Component, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AccessService } from '@app-core/services/access/access.service';
import { DataService } from '@app-core/services/data/data.service';
import { UserRoleManageService } from '@app-user-management/service/user-role-manage.service';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-sso-dialog',
  templateUrl: './sso-dialog.component.html',
  styleUrls: ['./sso-dialog.component.scss'],
})
export class SsoDialogComponent implements OnDestroy {
  public loader: boolean = false;
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  private userHash: string = '';

  constructor(
    public dialogRef: MatDialogRef<SsoDialogComponent>,
    public dataService: DataService,
    @Inject(MAT_DIALOG_DATA) public data,
    private accessService: AccessService,
    private userService: UserRoleManageService
  ) {}

  public rejectConsent() {
    this.dialogRef.close(false);
  }

  public async acceptConsent() {
    this.loader = true;
    this.fetchUserHash()
      .then((userHash) => {
        if (!userHash) {
          console.error('User hash is missing');
          this.dialogRef.close(false);
          this.loader = false;
          return;
        }

        this.userHash = userHash;
        this.updateSSOUser()
          .pipe(
            finalize(() => {
              this.loader = false;
              this.dialogRef.close(true);
            })
          )
          .subscribe(
            () => {},
            (error) => {
              console.error('Error creating SSO user:', error);
            }
          );
      })
      .catch((error) => {
        console.error('Error fetching user hash:', error);
        this.loader = false;
      });
  }

  private updateSSOUser() {
    this.loader = true;
    const body = {
      payload: {
        consentTimestamp: this.data.consentTime,
      },
    };
    return this.userService.updateSSOUser(encodeURIComponent(this.userHash), body).pipe(
      finalize(() => {
        this.loader = false;
      }),
      takeUntil(this.ngUnsubscribe)
    );
  }

  private async fetchUserHash(): Promise<string> {
    return Promise.resolve(this.accessService.getLoginInfo())
      .then((loginInfo) => loginInfo?.userHash || '')
      .catch((error) => {
        console.error('Error accessing login info:', error);
        return '';
      });
  }

  public ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
